
.SignUpInput{
    width:300px;
    margin-bottom:5px;
    color:var(--main_dark);
}

.SignUpInputLong{
    width:325px;
    margin-bottom:5px;
    color:var(--main_dark);
}

.SignUpButton{
    color:'white';
    background-color:var(--main_bright);
    width:320px;
}

.SignUpInfo{
    color:var(--main_dark);
}
