@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);

:root {
  --main_bright: #0c9b3c;
  /* --main_dark: #223127; */
  --main_dark: #086c2a;
  --sec_dark: #3caf63;
  --sec_mid: #baf4cd;
  --meal_bg: #ddffe8;
  /* --sec_light:#ddffe8; */
  /* --sec_light:#EAF2E3; */
  --sec_light:#f2f2f2;
  --sec_white: #fff;
  /* --main_contrast:#f46036;
  --sec_contrast:#FFD5C2;*/
  --main_contrast:#ff9100;
  --sec_contrast:#ffda99;/*#ffbb66*/
  --goal_neg_dark:#FF6666; 
  --goal_neg_light:#FFCCCC;
  --goal_nuet:"light-yellow";
  --goal_pos_light:#ccffcc;
  --goal_pos_dark:#66ff66;
  --mobile_width:"320px";
  --mobile_height:"500px";
  padding:0 !important;
  margin:0 !important;
  scrollbar-width: "thin" !important;
  font-family: "Poppins", sans-serif;
}

body{
  margin:0 !important;
  padding:0 !important;
  font-family: "Poppins", sans-serif;/* TODO - download better font*/
}


div::-webkit-scrollbar {
  height:7px !important;
  width:7px !important;
}


/* 
Alternate color scheme: 

auto_plan color
page font color
navbar and food white
food list background
full page background
dragging from color
dragging over color
nutrients label background
nutrients label font color
*/

/* .hero{
  position:"relative";
}

.hero_img{
  position:"absolute";
  height:"100vh";
  width:"100%",
}

.hero_title{
  color:"white";
  position:"absolute";
  font-family: "Poppins", sans-serif;
}

.hero_login{
  position:"absolute";
} */

/* .landing_nav{
  margin-right:10,
} */

.SignUpInput{
    width:300px;
    margin-bottom:5px;
    color:var(--main_dark);
}

.SignUpInputLong{
    width:325px;
    margin-bottom:5px;
    color:var(--main_dark);
}

.SignUpButton{
    color:'white';
    background-color:var(--main_bright);
    width:320px;
}

.SignUpInfo{
    color:var(--main_dark);
}

